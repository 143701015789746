import React from 'react';

export default function IdentityDocumentDetails({ formData, handleChange }) {
  return (
    <>
        <h2>Identity Document</h2>
        <hr className="my-4" />

        {/* ID Document Type */}
        <label className="my-4">
        <div className="form-label required">Select the document type</div>
        <select
            className="text-sm h-12 w-full sm:w-1/2 rounded-md border border-btn-background px-2"
            name="idDocumentType"
            value={formData.idDocumentType}
            onChange={handleChange}
            required
        >
            <option value="" disabled>Please Select</option>
            <option value="Passport">Passport</option>
            <option value="National ID Card">National ID Card</option>
            <option value="Driving License">Driving License</option>
        </select>
        <div className="form-comment sm:w-1/2">Note: For Non-EEA citizens and Non-EEA residents only the Passport is accepted</div>
        </label>

        {/* ID Document */}
        <label className="mb-4">
            <div className="form-label required">Identity Document (front)</div>
            <input
                className="text-sm py-4 w-full sm:w-1/2 overflow-hidden"
                type="file"
                name="idDocumentFront"
                onChange={handleChange}
                required
            />
            <div className="form-comment text-justify">Document must be high quality scans in colour. All 4 corners of the documents must be visible.</div>
        </label>

        <label className="mb-4">
            <div className="form-label required">Identity Document (back)</div>
            <input
                className="text-sm py-4 w-full sm:w-1/2 overflow-hidden"
                type="file"
                name="idDocumentBack"
                onChange={handleChange}
                required
            />
            <div className="form-comment text-justify">If Passport, please submit the cover as back page.</div>
        </label>
    </>
  );
}