import React from 'react';

export default function OwnershipDetails({ formData, handleChange }) {
    return (
        <>
            <h2 className="mt-4">Beneficial Owners</h2>
            <hr className="my-4" />

            {/* Ownership Document */}
            <label className="mb-4">
                <div className="form-label required">Please upload an Official Document showing who owns the Company</div>
                <input
                    className="text-sm my-2 py-2 w-full sm:w-1/2 overflow-hidden"
                    type="file"
                    name="ownershipDocument"
                    onChange={handleChange}
                    required
                />
                <div className="form-comment text-justify">For example: Share Registry, Share Certificate, Articles of Association (as applicable).</div>
            </label>
            
            <p className="text-sm text-justify mt-4 mb-6 p-4 bg-btn-background border border-btn-background-hover rounded-md">
                An Ultimate Beneficial Owner (UBO) is a person who has ultimate control over a business and owns at least 25% of its shares. A Beneficial Owner (BO) is anybody who owns shares and benefits financially from a company.
            </p>

            {/* Ownership Structure */}
            <label className="mb-4">
                <div className="form-label required">Please select</div>
                <div className="my-4">
                    <input
                    className="mx-2"
                    type="radio"
                    id="ubo"
                    name="ownershipStructure"
                    value="ubo"
                    checked={formData.ownershipStructure === 'ubo'}
                    onChange={handleChange}
                    required
                    />
                    <label htmlFor="ubo">The company has one or more UBOs.</label>
                    <br />
                    <input
                    className="mx-2"
                    type="radio"
                    id="no_ubo"
                    name="ownershipStructure"
                    value="no_ubo"
                    checked={formData.ownershipStructure === 'no_ubo'}
                    onChange={handleChange}
                    required
                    />
                    <label htmlFor="no_ubo">The company has no single UBO.</label>
                    <br />
                    <input
                    className="mx-2"
                    type="radio"
                    id="public"
                    name="ownershipStructure"
                    value="public"
                    checked={formData.ownershipStructure === 'public'}
                    onChange={handleChange}
                    required
                    />
                    <label htmlFor="public">The company is publicly listed on a regulated stock exchange.</label>
                </div>
            </label>

            {/* The company has one or more single UBOs */}
            {formData.ownershipStructure === 'ubo' && (
                <>
                    <label className="mb-4">
                    <div className="form-label required">Number of Ultimate Beneficial Owners with an interest of 25% or more</div>
                    <select
                        className="text-sm h-12 w-1/2 sm:w-1/4 rounded-md border border-btn-background px-2"
                        name="numberOfUBOs"
                        value={formData.numberOfUBOs}
                        onChange={handleChange}
                        required
                    >
                        <option value="" selected>Please select</option>
                        <option key='1' value='1'>1</option>
                        <option key='2' value='2'>2</option>
                        <option key='3' value='3'>3</option>
                        <option key='4' value='4'>4</option>
                    </select>
                    </label>

                    <p className="text-justify mt-4 mb-6 p-4 border border-btn-background-hover rounded-md">
                    <strong>Each Beneficial Owner must fill in and submit <a href="/forms/individual" title="Individual Form" target="_blank">this individual form</a> before proceeding!</strong><br />
                    For verification, please type below the name of each Beneficial Owner who has completed the form.
                    </p>

                    {/* Ubo1 */}
                    {((formData.numberOfUBOs === 1) || (formData.numberOfUBOs === 2) || (formData.numberOfUBOs === 3) || (formData.numberOfUBOs === 4)) && (
                        <>
                            <div className="form-label required">Beneficial Owner 1</div>
                            <div className="grid grid-cols-2 gap-2">
                                <label className="mb-4">
                                    <input
                                        className="h-12 w-full"
                                        type="text"
                                        name="firstNameUbo1"
                                        value={formData.firstNameUbo1}
                                        onChange={handleChange}
                                    />
                                    <div className="form-comment text-justify">First Name</div>
                                </label>
                                <label className="mb-4">
                                    <input
                                        className="h-12 w-full"
                                        type="text"
                                        name="lastNameUbo1"
                                        value={formData.lastNameUbo1}
                                        onChange={handleChange}
                                    />
                                    <div className="form-comment text-justify">Last Name</div>
                                </label>
                            </div>
                        </>
                    )}

                    {/* Ubo2 */}
                    {((formData.numberOfUBOs === 2) || (formData.numberOfUBOs === 3) || (formData.numberOfUBOs === 4)) && (
                        <>
                            <div className="form-label required">Beneficial Owner 2</div>
                            <div className="grid grid-cols-2 gap-2">
                                <label className="mb-4">
                                    <input
                                        className="h-12 w-full"
                                        type="text"
                                        name="firstNameUbo2"
                                        value={formData.firstNameUbo2}
                                        onChange={handleChange}
                                    />
                                    <div className="form-comment text-justify">First Name</div>
                                </label>
                                <label className="mb-4">
                                    <input
                                        className="h-12 w-full"
                                        type="text"
                                        name="lastNameUbo2"
                                        value={formData.lastNameUbo2}
                                        onChange={handleChange}
                                    />
                                    <div className="form-comment text-justify">Last Name</div>
                                </label>
                            </div>
                        </>
                    )}

                    {/* Ubo3 */}
                    {((formData.numberOfUBOs === 3) || (formData.numberOfUBOs === 4)) && (
                        <>
                            <div className="form-label required">Beneficial Owner 3</div>
                            <div className="grid grid-cols-2 gap-2">
                                <label className="mb-4">
                                    <input
                                        className="h-12 w-full"
                                        type="text"
                                        name="firstNameUbo3"
                                        value={formData.firstNameUbo3}
                                        onChange={handleChange}
                                    />
                                    <div className="form-comment text-justify">First Name</div>
                                </label>
                                <label className="mb-4">
                                    <input
                                        className="h-12 w-full"
                                        type="text"
                                        name="lastNameUbo3"
                                        value={formData.lastNameUbo3}
                                        onChange={handleChange}
                                    />
                                    <div className="form-comment text-justify">Last Name</div>
                                </label>
                            </div>
                        </>
                    )}

                    {/* Ubo4 */}
                    {formData.numberOfUBOs === 4 && (
                        <>
                            <div className="form-label required">Beneficial Owner 4</div>
                            <div className="grid grid-cols-2 gap-2">
                                <label className="mb-4">
                                    <input
                                        className="h-12 w-full"
                                        type="text"
                                        name="firstNameUbo4"
                                        value={formData.firstNameUbo4}
                                        onChange={handleChange}
                                    />
                                    <div className="form-comment text-justify">First Name</div>
                                </label>
                                <label className="mb-4">
                                    <input
                                        className="h-12 w-full"
                                        type="text"
                                        name="lastNameUbo4"
                                        value={formData.lastNameUbo4}
                                        onChange={handleChange}
                                    />
                                    <div className="form-comment text-justify">Last Name</div>
                                </label>
                            </div>
                        </>
                    )}

                    <label className="mb-4">
                    <input
                        className="mx-2"
                        type="checkbox"
                        name="submittedFormsUbo"
                        checked={formData.submittedFormsUbo || false}
                        onChange={handleChange}
                        required
                    />
                    <label className="required" htmlFor="submittedFormsUbo">I confirm that all Beneficial Owners have submitted the required form.</label>
                    </label>
                </>
            )}

            {/* The company has no UBOs */}
            {formData.ownershipStructure === 'no_ubo' && (
                <>
                    <p className="text-justify mt-4 mb-6 p-4 border border-btn-background-hover rounded-md">
                    <strong>Each one of the 3 largest Beneficial Owners must fill and submit <a href="/forms/individual" title="Individual Form" target="_blank">this individual form</a> before proceeding!</strong><br />
                    For verification, please type below the name of each Beneficial Owner who has completed the form.
                    </p>

                    {/* Ubo1 */}
                    <div className="form-label required">Beneficial Owner 1</div>
                    <div className="grid grid-cols-2 gap-2">
                        <label className="mb-4">
                            <input
                                className="h-12 w-full"
                                type="text"
                                name="firstNameUbo1"
                                value={formData.firstNameUbo1}
                                onChange={handleChange}
                            />
                            <div className="form-comment text-justify">First Name</div>
                        </label>
                        <label className="mb-4">
                            <input
                                className="h-12 w-full"
                                type="text"
                                name="lastNameUbo1"
                                value={formData.lastNameUbo1}
                                onChange={handleChange}
                            />
                            <div className="form-comment text-justify">Last Name</div>
                        </label>
                    </div>

                    {/* Ubo2 */}
                    <div className="form-label required">Beneficial Owner 2</div>
                    <div className="grid grid-cols-2 gap-2">
                        <label className="mb-4">
                            <input
                                className="h-12 w-full"
                                type="text"
                                name="firstNameUbo2"
                                value={formData.firstNameUbo2}
                                onChange={handleChange}
                            />
                            <div className="form-comment text-justify">First Name</div>
                        </label>
                        <label className="mb-4">
                            <input
                                className="h-12 w-full"
                                type="text"
                                name="lastNameUbo2"
                                value={formData.lastNameUbo2}
                                onChange={handleChange}
                            />
                            <div className="form-comment text-justify">Last Name</div>
                        </label>
                    </div>

                    {/* Ubo3 */}
                    <div className="form-label required">Beneficial Owner 3</div>
                    <div className="grid grid-cols-2 gap-2">
                        <label className="mb-4">
                            <input
                                className="h-12 w-full"
                                type="text"
                                name="firstNameUbo3"
                                value={formData.firstNameUbo3}
                                onChange={handleChange}
                            />
                            <div className="form-comment text-justify">First Name</div>
                        </label>
                        <label className="mb-4">
                            <input
                                className="h-12 w-full"
                                type="text"
                                name="lastNameUbo3"
                                value={formData.lastNameUbo3}
                                onChange={handleChange}
                            />
                            <div className="form-comment text-justify">Last Name</div>
                        </label>
                    </div>

                    <label className="mb-4">
                    <input
                        className="mx-2"
                        type="checkbox"
                        name="submittedFormsUbo"
                        checked={formData.submittedFormsUbo || false}
                        onChange={handleChange}
                        required
                    />
                    <label className="required" htmlFor="submittedFormsUbo">I confirm that all Beneficial Owners have submitted the required <a href="/forms/individual" title="Individual Form" target="_blank">individual form</a>.</label>
                    </label>
                </>
            )}

            {/* Public Company       */}
            {formData.ownershipStructure === 'public' && (
                <>
                    <label className="mb-4">
                        <div className="form-label required">The stock exchange you are listed in</div>
                        <input
                            className="h-12 w-full sm:w-1/2"
                            type="text"
                            name="stockExchangeName"
                            value={formData.stockExchangeName}
                            onChange={handleChange}
                        />
                    </label>

                    <label className="mb-4">
                        <div className="form-label required">Your trading ticker</div>
                        <input
                            className="h-12 w-full sm:w-1/2"
                            type="text"
                            name="tradingTicker"
                            value={formData.tradingTicker}
                            onChange={handleChange}
                        />
                    </label>

                    <label className="mb-4">
                        <div className="form-label required">A link to your exchange listing or prospectus</div>
                        <input
                            className="h-12 w-full sm:w-1/2"
                            type="text"
                            name="linkToExchange"
                            value={formData.linkToExchange}
                            onChange={handleChange}
                        />
                    </label>
                </>
            )}      
        </>
    );
}