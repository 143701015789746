//src/partials/footer.jsx

import { Link } from "react-router-dom";

const currentYear = new Date().getFullYear();

export default function Footer() {
    return (
        <div>
            <div><Link className="font-bold hover:underline text-foreground" to='/' title="IxoDex">IxoDex</Link>. New Gen Fintech.</div>
            {/* <div className="mb-4">Make reliable decisions about who to do business with!</div> */}
            <div>
              <Link to='/legal/terms' title='Terms of Service'>Terms of Service</Link>&nbsp;|&nbsp;
              <Link to='/legal/privacy' title='Privacy Policy'>Privacy Policy</Link>&nbsp;|&nbsp;
              <Link to='/contact' title='Contact IxoDex'>Contact</Link>
            </div>
            <div>
                {/* Powered by{" "}
                <a
                    href="https://ixodex.com"
                    target="_blank"
                    className="font-bold hover:underline text-foreground"
                    rel="noreferrer"
                    title="Go to IxoDex website"
                >
                    IxoDex
                </a>.&nbsp; */}
                Made with ❤️ in Lithuania
            </div>
            <div>
                <p>&copy; {`2021 - ${currentYear}. IxoDex UAB. All rights reserved.`}&nbsp;</p>
            </div>
        </div>
    );
}