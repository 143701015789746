import React from 'react';

export default function RelationToCompanyDetails({ formData, handleChange }) {
  return (
    <>
        <h2>Relation to Company</h2>
        <hr className="my-4" />

        <div className="text-sm text-justify mt-4 mb-6 p-4 bg-btn-background border border-btn-background-hover rounded-md">
            <p>
            An <strong>Ultimate Beneficial Owner (UBO)</strong> is a person who has ultimate control over a business and owns at least 25% of its shares. A Beneficial Owner (BO) is anybody who owns shares and benefits financially from a company.
            </p>
            <p className="mt-4">
            A <strong>Director</strong> refers to any natural person who has direct control over the company, in either a Director or Executive role, possesses voting rights in Board meetings and exerts effective control over the company.
            </p>
        </div>

        {/* relationToCompany */}
        {formData.isMainRep === true && (
        <label className="mb-4">
            <div className="form-label required">In relation to the Company, I am:</div>
            <div className="my-4">
            <input
                className="mx-2"
                type="radio"
                id="relationToCompanyDirectorOnly"
                name="relationToCompany"
                value="DirectorOnly"
                checked={formData.relationToCompany === "DirectorOnly"}
                onChange={handleChange}
                required
            />
            <label htmlFor="relationToCompanyDirectorOnly">Only a Director</label>
            <br />
            <input
                className="mx-2"
                type="radio"
                id="relationToCompanyUboOnly"
                name="relationToCompany"
                value="UboOnly"
                checked={formData.relationToCompany === "UboOnly"}
                onChange={handleChange}
                required
            />
            <label htmlFor="relationToCompanyUboOnly">Only an Ultimate Beneficial Owner</label>
            <br />
            <input
                className="mx-2"
                type="radio"
                id="relationToCompanyUboAndDirector"
                name="relationToCompany"
                value="UboAndDirector"
                checked={formData.relationToCompany === "UboAndDirector"}
                onChange={handleChange}
                required
            />
            <label htmlFor="relationToCompanyUboAndDirector">Both an Ultimate Beneficial Owner and a Director</label>
            <br />
            <input
                className="mx-2"
                type="radio"
                id="relationToCompanyOther"
                name="relationToCompany"
                value="Other"
                checked={formData.relationToCompany === "Other"}
                onChange={handleChange}
                required
            />
            <label htmlFor="relationToCompanyOther">Other / None of mentioned above</label>
            </div>
        </label>
        )}

        {/* Title / Role */}
        <label className="mb-4">
        <div className="form-label required">Title / Role</div>
        <input
            className="h-12 w-full sm:w-1/2"
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
        />
        <div className="form-comment">Director, CEO, CBDO, Manager, Chairman, other...</div>
        </label>

        {/* Ownership Percentage */}
        {(formData.relationToCompany === "UboOnly" || formData.relationToCompany === "UboAndDirector") && (
        <label className="mb-4">
            <div className="form-label required">Ownership Percentage</div>
            <input
                className="h-12 w-full sm:w-1/2"
                type="number" 
                step="0.01"
                name="OwnershipPercentage"
                value={formData.OwnershipPercentage}
                onChange={handleChange}
                required
            />
        </label>
        )}
    </>
  );
}