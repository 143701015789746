import { useState } from 'react';
import { supabase } from '../../supabaseClient';
import { Helmet } from 'react-helmet-async';

export default function Auth() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null); // To store and display errors

  const handleLogin = async (event) => {
    event.preventDefault();

    setLoading(true);
    setError(null); // Reset error state on each login attempt

    const { error } = await supabase.auth.signInWithOtp({ email });

    if (error) {
      if (error.message.includes('expired')) {
        setError('The magic link has expired. Please request a new one.');
      } else {
        setError(error.error_description || error.message);
      }
    } else {
      alert('Check your email for the login link!');
    }
    
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>Account Access | IxoDex WebApp </title>
        <meta name="description" content="Account Access - IxoDex Web App. Sign in via magic link with your email below." />
        <meta name="robots" content="noindex, nofollow" />
        <link rel="canonical" href="/" />
      </Helmet>
      <div className="row flex flex-center">
        <div className="mx-auto mt-16">
          <img className="w-[150px] mx-auto" src="/logo_big.png" title="Account Login" alt="Account Login" />
          <h1 className='text-center mt-6'>Account Access</h1>

          {error && <div className="error-message">{error}</div>} {/* Display error */}

          <form className="rounded-md border mt-6 p-6 text-center" onSubmit={handleLogin}>
          <p className="mb-6 w-full md:w-[350px]">Sign in via magic link with your email below</p>
            <div>
              <input
                className="h-10 w-full md:w-[350px]"
                type="email"
                placeholder="Your email"
                value={email}
                required={true}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <button className="mt-8 mb-2 py-2 px-4 rounded-md no-underline btn-active w-full md:w-[200px]" disabled={loading}>
                {loading ? <span>Loading</span> : <span>Send magic link</span>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>

  );
}