import React from 'react';
import { countries } from '../../../countries';

export default function PepDetails({ formData, handleChange }) {
  return (
    <>
        <h2 className="mt-4">Politically Exposed Person Status</h2>
        <hr className="my-4" />

        <p className="text-sm text-justify mt-4 mb-6 p-4 bg-btn-background border border-btn-background-hover rounded-md">
        Politically Exposed Persons (PEPs) are individuals who are or have been entrusted with prominent public functions, for example Head of State or government, senior politicians, senior government, judicial or military officials, senior executives of state owned corporations and important political party officials. Family members and close associates of such persons are also considered as PEPs.
        </p>

        {/* Is PEP? */}
        <label className="mb-4">
        <div className="form-label required">Are you a Politically Exposed Person?</div>
        <div className="my-4"> 
            <input
            className="mx-2"
            type="radio"
            id="isPepYes"
            name="isPep"
            value="true"
            checked={formData.isPep === true}
            onChange={handleChange}
            required
            />
            <label htmlFor="isPepYes">Yes</label>
            <br />
            <input
            className="mx-2"
            type="radio"
            id="isPepNo"
            name="isPep"
            value="false"
            checked={formData.isPep === false}
            onChange={handleChange}
            required
            />
            <label htmlFor="isPepNo">No</label>
        </div>
        </label>

        {formData.isPep === true && (
        <>
            <label className="mb-4">
            <div className="form-label required">Country of Political Exposure</div>
            <select
                className="text-sm h-12 w-full sm:w-1/2 rounded-md border border-btn-background px-2"
                name="pepCountry"
                value={formData.pepCountry}
                onChange={handleChange}
                required
            >
                <option value="" disabled>Please Select</option>
                {countries.map((pepCountry) => (
                <option key={pepCountry} value={pepCountry}>
                    {pepCountry}
                </option>
                ))}
            </select>
            </label>

            {/* PEP Title */}
            <label className="mb-2">
            <div className="form-label required">Position / Title / Association</div>
            <input
                className="h-12 w-full sm:w-1/2"
                name="pepTitle"
                type="text"
                value={formData.pepTitle}
                onChange={handleChange}
                required
            />
            </label>
            
            {/* Details of PEP */}
            <label className="mb-2">
            <div className="form-label required">Details of Political Exposure or Association</div>
            <textarea
                className="h-24 w-full sm:w-1/2"
                name="pepDetails"
                value={formData.pepDetails}
                onChange={handleChange}
                required
                placeholder="Type here..."
                rows={4}
                autoFocus
            />
            </label>
        </>
        )}
    </>
  );
}