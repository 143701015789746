import { useState, useEffect } from 'react';
import { supabase } from './supabaseClient';
import Auth from './pages/auth/Auth';
import Account from './pages/account/Account';
import BusinessForm from './pages/forms/business/BusinessForm';
import IndividualForm from './pages/forms/individual/IndividualForm';
import PersonalForm from './pages/forms/personal/PersonalForm';
import Dashboard from './pages/Dashboard';
import Footer from './partials/footer';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Sidebar, { SidebarItem } from './partials/Sidebar';
import { LayoutDashboard, Briefcase, UserCheck, UserCheck2Icon } from 'lucide-react';

function App() {
  const [session, setSession] = useState(null);
  const [sidebarExpanded, setSidebarExpanded] = useState(true); // New state to track sidebar expansion

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  if (!session) {
    return <Auth />;
  }

  return (
    <Router>
      <div className="flex">
        <Sidebar expanded={sidebarExpanded} setExpanded={setSidebarExpanded}>
          <SidebarItem
            icon={<LayoutDashboard size={20} />}
            text="Dashboard"
            to="/dashboard"
          />
          <hr className='mt-4 mb-4' />
          <SidebarItem
            icon={<Briefcase size={20} />}
            text="Business&nbsp;Form"
            to="/forms/business"
          />
          <SidebarItem
            icon={<UserCheck size={20} />}
            text="Individual&nbsp;Form"
            to="/forms/individual"
          />
          <hr className='my-4' />
          <SidebarItem
            icon={<UserCheck2Icon size={20} />}
            text="Personal&nbsp;Form"
            to="/forms/personal"
          />
        </Sidebar>

        <main className={`flex-1 p-8 transition-all ${sidebarExpanded ? 'sm:ml-64' : 'ml-16'}`}>
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/account" element={<Account key={session.user.id} session={session} />} />
            <Route path="/forms/business" element={<BusinessForm />} />
            <Route path="/forms/individual" element={<IndividualForm />} />
            <Route path="/forms/personal" element={<PersonalForm />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </main>
      </div>
      <footer className={`w-full border-t border-t-foreground/10 p-8 flex flex-col justify-center text-xs ${sidebarExpanded ? 'sm:ml-64' : 'ml-16'}`}>
        <Footer />
      </footer>
    </Router>
  );
}

export default App;