import React from 'react';

export default function AddressDocumentDetails({ formData, handleChange }) {
  return (
    <>
        <h2>Address Document</h2>
        <hr className="my-4" />

        {/* Address Document Type */}
        <label className="my-4">
        <div className="form-label required">Select the document type</div>
        <select
            className="text-sm h-12 w-full sm:w-1/2 rounded-md border border-btn-background px-2"
            name="addressDocumentType"
            value={formData.addressDocumentType}
            onChange={handleChange}
            required
        >
            <option value="" disabled>Please Select</option>
            <option value="Utility Bill">Utility Bill</option>
            <option value="Bank Statement">Bank Statement</option>
            <option value="Rental Agreement">Rental Agreement</option>
            <option value="Official Government Confirmation">Official Government Confirmation</option>
        </select>
        </label>

        {/* Address Document */}
        <label className="mb-4">
            <div className="form-label required">Proof of Address</div>
            <input
                className="text-sm py-4 w-full sm:w-1/2 overflow-hidden"
                type="file"
                name="addressDocument"
                onChange={handleChange}
                required
            />
            <div className="form-comment text-justify">Must have been issued in the last 90 days. Must show your residential address and full name.</div>
        </label>
    </>
  );
}