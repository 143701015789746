import React from 'react';

export default function DeclarationSection({ formData, handleChange }) {
  return (
    <>
        <h2>Declaration</h2>
        <hr className="mt-4 mb-8" />

        <p className="mb-2">I confirm that:</p>
        <ul className="list-disc pl-5 text-justify">
        <li className="before:content-[]">I am the authorized representative, authorized to represent the company towards IxoDex.</li>
        <li className="before:content-[]">I am not acting on behalf of any third parties with respect to their shareholding in the aforementioned entities.</li>
        <li className="before:content-[]">I have not been convicted of an offence (other than a minor traffic offence) or serious crime.</li>
        <li className="before:content-[]">I have never been adjudged bankrupt.</li>
        <li className="before:content-[]">I have never been the subject of an investigation by a government, professional or other regulatory body.</li>
        <li className="before:content-[]">I have never been a director, shareholder, officer or manager of a business entity that has been adjudged bankrupt, compulsorily wound up or has made any compromise or arrangement with its creditors or has otherwise ceased trading in circumstances where its creditors did not receive or have not yet received full settlement of claims.</li>
        </ul>
        <p className="mt-4 mb-2">I further confirm and agree that:</p>
        <ul className="list-disc pl-5 text-justify">
        <li className="before:content-[]">All documents and information given in this form and complementary are true, correct, complete and up to date in all respects and no information has been withheld.</li>
        <li className="before:content-[]">To cooperate with IxoDex in the eventuality that it requires further documentation.</li>
        <li className="before:content-[]">To provide IxoDex with up-to-date information and documents as may be required from time to time to comply with anti-money laundering and other regulatory obligations.</li>
        <li className="before:content-[]">To notify IxoDex as soon as possible in the event of a change in the Directors, Signatories, Ultimate beneficial Owner(s) or in the event of issuance of new shares.</li>
        <li className="before:content-[]">To immediately inform Ixodex if any of the information provided becomes outdated or is no longer correct.</li>
        <li className="before:content-[]">That the provision of any false or misleading information or the suppression of any material fact will render my relationship with IxoDex liable to termination and to any further action deemed fit by IxoDex.</li>
        <li className="before:content-[]">To indemnify IxoDex if any representation or declaration made hereunder or in any other ancillary document is incorrect, false, or misleading, in any way.</li>
        </ul>

        <hr className="mt-4 mb-8" />

        {/* Accepted Terms */}
        <label className="flex items-start mb-4">
        <input
            className="mt-2 ml-2 mr-4"
            type="checkbox"
            name="acceptedTerms"
            checked={formData.acceptedTerms || false}
            onChange={handleChange}
            required
        />
        <label className="text-justify" htmlFor="acceptedTerms">I have accepted the <a href="/legal/terms" title="Terms of Service" target="_blank">Terms of Service</a> and I confirm that the information provided is true, correct and up to date. I understand that the provision of any false or misleading information or the suppression of any material fact will render my relationship with IxoDex liable to termination and to any further action deemed fit by IxoDex.</label>
        </label>
    </>
  );
}