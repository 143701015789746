import React from 'react';

export default function RepresentativeDetails({ formData, handleChange }) {
    return (
        <>
            <h2>Representative</h2>
            <hr className="my-4"/>
            <p className="text-sm text-justify mt-4 mb-6 p-4 bg-btn-background border border-btn-background-hover rounded-md">
                A representative refers to any natural person who has managerial or executive powers over the company, in either an Authorized Representative or Executive role, possessing the necessary powers to make decisions on behalf of the company.
            </p>

            {/* isRepresentativeDirector */}
            <label className="mb-4">
                <div className="form-label required">Please select</div>
                <div className="my-4">
                    <input
                        className="mx-2"
                        type="radio"
                        id="isRepresentativeDirectorYes"
                        name="isRepresentativeDirector"
                        value={true}
                        checked={formData.isRepresentativeDirector === true}
                        onChange={handleChange}
                        required
                    />
                    <label htmlFor="isRepresentativeDirectorYes">The Representative is the same individual as one of the listed Directors.</label>
                    <br />
                    <input
                        className="mx-2"
                        type="radio"
                        id="isRepresentativeDirectorNo"
                        name="isRepresentativeDirector"
                        value={false}
                        checked={formData.isRepresentativeDirector === false}
                        onChange={handleChange}
                        required
                    />
                    <label htmlFor="isRepresentativeDirectorNo">The Representative is a different individual from the listed Directors.</label>
                </div>
            </label>

            {/* Representative Name */}
            <div className="form-label required">Representative</div>
            <div className="grid grid-cols-2 gap-2">
                <label className="mb-4">
                    <input
                        className="h-12 w-full"
                        type="text"
                        name="firstNameRepresentative1"
                        value={formData.firstNameRepresentative1}
                        onChange={handleChange}
                    />
                    <div className="form-comment text-justify">First Name</div>
                </label>
                <label className="mb-4">
                    <input
                        className="h-12 w-full"
                        type="text"
                        name="lastNameRepresentative1"
                        value={formData.lastNameRepresentative1}
                        onChange={handleChange}
                    />
                    <div className="form-comment text-justify">Last Name</div>
                </label>
            </div>

            <label className="my-4">
                    <input
                        className="mx-2"
                        type="checkbox"
                        name="submittedFormsRepresentative"
                        checked={formData.submittedFormsRepresentative || false}
                        onChange={handleChange}
                        required
                    />
                    <label className="required" htmlFor="submittedFormsRepresentative">The Representative has submitted the required <a href="/forms/individual" title="Individual Form" target="_blank">individual form</a>.</label>
            </label>
        </>
    );
}