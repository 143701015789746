import React from 'react';

export default function DirectorDetails({ formData, handleChange }) {
    return (
        <>
            <h2>Directors</h2>
            <hr className="my-4" />
            <p className="text-sm text-justify mt-4 mb-6 p-4 bg-btn-background border border-btn-background-hover rounded-md">
                A Director refers to any natural person who has direct control over the company, in either a Director or Executive role, possesses voting rights in Board meetings and exerts effective control over the company.
            </p>

            {/* Number of Directors */}
            <label className="mb-4">
                <div className="form-label required">The number of Directors in your company</div>
                <input
                    className="h-12 w-1/2 sm:w-1/4"
                    type="text"
                    name="numberOfDirectors"
                    value={formData.numberOfDirectors}
                    onChange={handleChange}
                />
            </label>

            {/* Are All Directors UBOs */}
            <label className="mb-4">
                <div className="form-label required">Please select</div>
                <div className="my-4">
                    <input
                    className="mx-2"
                    type="radio"
                    id="areAllDirectorsUboYes"
                    name="areAllDirectorsUbo"
                    value={true}
                    checked={formData.areAllDirectorsUbo === true}
                    onChange={handleChange}
                    required
                    />
                    <label htmlFor="areAllDirectorsUboYes">All Directors are Ultimate Beneficial Owners and have already filled an individual declaration form.</label>
                    <br />
                    <input
                    className="mx-2"
                    type="radio"
                    id="areAllDirectorsUboNo"
                    name="areAllDirectorsUbo"
                    value={false}
                    checked={formData.areAllDirectorsUbo === false}
                    onChange={handleChange}
                    required
                    />
                    <label htmlFor="areAllDirectorsUboNo">One or more Directors are not Ultimate Beneficial Owners (or are minority UBOs with less than 25%).</label>
                </div>
            </label>

            {formData.areAllDirectorsUbo === false && (
                <>
                    <p className="text-justify mb-6 p-4 border border-btn-background-hover rounded-md">
                    <strong>Each Director that did not fill out an Ultimate Beneficial Owner form in the previous section must fill in and submit <a href="/forms/individual" title="Individual Form" target="_blank">this individual form</a> before proceeding!</strong><br />
                    </p>

                    <label className="mb-4">
                    <input
                        className="mx-2"
                        type="checkbox"
                        name="submittedFormsDirector"
                        checked={formData.submittedFormsDirector === true}
                        onChange={handleChange}
                        required
                    />
                    <label className="required" htmlFor="submittedFormsDirector">All Directors have submitted the required <a href="/forms/individual" title="Individual Form" target="_blank">individual form</a>.</label>
                    </label>
                </>
            )}
        </>
    );
}