// src/pages/BusinessForm.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Use React Router for navigation
import { v4 as uuidv4 } from 'uuid';
import { Helmet } from "react-helmet-async"; // For handling meta tags
import { supabase } from '../../../supabaseClient';
// import { countries } from '../../../countries';
import CompanyDetails from './CompanyDetails';
import RegulationDetails from './RegulationDetails';
import OwnershipDetails from './OwnershipDetails';
import DirectorDetails from './DirectorDetails';
import RepresentativeDetails from './RepresentativeDetails';
import DeclarationSection from './DeclarationSection';

export default function BusinessForm() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    companyEmail: '',
    companyName: '',
    streetAddress: '',
    city: '',
    postalCode: '',
    country: '',
    hasTradeName: '',
    tradeName: '',
    registrationNumber: '',
    lei: '',
    description: '',
    bankStatement: null,
    isRegulated: '',
    regulationCountry: '',
    regulatorName: '',
    licenseNumber: '',
    licenseLink: '',
    providesFinancialServices: '',
    ownershipDocument: null,
    ownershipStructure: '',
    numberOfUBOs: 0,
    firstNameUbo1: '',
    lastNameUbo1: '',
    firstNameUbo2: '',
    lastNameUbo2: '',
    firstNameUbo3: '',
    lastNameUbo3: '',
    firstNameUbo4: '',
    lastNameUbo4: '',
    submittedFormsUbo: false,
    stockExchangeName: '',
    tradingTicker: '',
    linkToExchange: '',
    numberOfDirectors: '',
    areAllDirectorsUbo: null,
    submittedFormsDirector: false,
    isRepresentativeDirector: false,
    firstNameRepresentative1: '',
    lastNameRepresentative1: '',
    submittedFormsRepresentative: false,
    acceptedTerms: false,
  });

  const [errorMessage, setErrorMessage] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, files, checked } = e.target;

    if (type === 'file' && files && files.length > 0) {
        const file = files[0];
        const maxFileSize = 1 * 1024 * 1024; // 1MB in bytes

        if (file.size > maxFileSize) {
            setErrorMessage(`File size should not exceed 1MB. Your file is ${(file.size / 1024 / 1024).toFixed(2)}MB.`);
            return;
        } else {
            setErrorMessage(null); // Clear previous error message
        }

        setFormData((prev) => ({
            ...prev,
            [name]: file, // Store the file object
        }));
    } else if (type === 'radio' && (value === 'true' || value === 'false')) {
        const booleanValue = value === 'true';

        // Set areAllDirectorsUbo and adjust submittedFormsDirector
        if (name === 'areAllDirectorsUbo') {
            setFormData((prev) => ({
                ...prev,
                areAllDirectorsUbo: booleanValue,
                submittedFormsDirector: booleanValue ? true : prev.submittedFormsDirector,
            }));
        } else {
            setFormData((prev) => ({
                ...prev,
                [name]: booleanValue,
            }));
        }
    } else if (type === 'select-one' && name === 'numberOfUBOs') {
        // Ensure numberOfUBOs is treated as a number
        setFormData((prev) => ({
            ...prev,
            [name]: parseInt(value, 10),
        }));
    
    } else {
        setFormData((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Fetch the current user's profile from the profiles table
    const { data: { user } } = await supabase.auth.getUser();

    if (!user) {
      navigate('/dashboard');
      return;
    }
  
    // Fetch the profile data from the "profiles" table
    const { data: profile, error: profileError } = await supabase
      .from('profiles')
      .select('*')
      .eq('id', user.id)
      .single();
  
    if (profileError) {
      setErrorMessage(`Error fetching profile: ${profileError.message}`);
      return;
    }

    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
    const formattedTime = currentDate.toTimeString().split(' ')[0].replace(/:/g, '-'); // Format time as HH-MM-SS

    const sanitizeString = (str) => {
        return str
            .normalize('NFD')  // Normalize to NFD Unicode form
            .replace(/[\u0300-\u036f]/g, '')  // Remove diacritics
            .replace(/[^a-zA-Z0-9-_]/g, '-')  // Replace non-alphanumeric characters with hyphens
            .replace(/\s+/g, '-');  // Replace spaces with hyphens
    };

    const sanitizePath = (path) => {
        const parts = path.split('/');
        const filename = parts.pop() || '';

        const sanitizedParts = parts.map(part => sanitizeString(part));
        const sanitizedFilename = filename.split('.').slice(0, -1).join('.')
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/[^a-zA-Z0-9-_]/g, '-')
            .replace(/\s+/g, '-');

        const extension = filename.split('.').pop();
        const uniqueIdentifier = uuidv4();

        return [...sanitizedParts, `${sanitizedFilename}-${uniqueIdentifier}.${extension}`].join('/');
    };

    let bankStatementUrl = '';
    if (formData.bankStatement) {
        const filePath = sanitizePath(`${formattedDate}/${sanitizeString(formData.companyName)}/${formattedTime}/bank-statement/${formData.bankStatement.name}`);
        const { data: uploadData, error: uploadError } = await supabase.storage
            .from('business-uploads')
            .upload(filePath, formData.bankStatement);

        if (uploadError) {
            setErrorMessage(`Error uploading file: ${uploadError.message}`);
            return;
        }
        bankStatementUrl = uploadData?.path ?? '';
    }

    let ownershipDocumentUrl = '';
    if (formData.ownershipDocument) {
        const filePath = sanitizePath(`${formattedDate}/${sanitizeString(formData.companyName)}/${formattedTime}/ownership/${formData.ownershipDocument.name}`);
        const { data: uploadData, error: uploadError } = await supabase.storage
            .from('business-uploads')
            .upload(filePath, formData.ownershipDocument);

        if (uploadError) {
            setErrorMessage(`Error uploading file: ${uploadError.message}`);
            return;
        }
        ownershipDocumentUrl = uploadData?.path ?? '';
    }

    const newFormData = {
        ...formData,
        bankStatement: bankStatementUrl,
        ownershipDocument: ownershipDocumentUrl,
        submissionDate: currentDate.toISOString(),
        acceptedTerms: formData.acceptedTerms || false,
        hasTradeName: formData.hasTradeName || false,
        isRegulated: formData.isRegulated || false,
        providesFinancialServices: formData.providesFinancialServices || false,
        submittedFormsUbo: formData.submittedFormsUbo || false,
        submittedFormsDirector: formData.submittedFormsDirector || false,
        isRepresentativeDirector: formData.isRepresentativeDirector || false,
        submittedFormsRepresentative: formData.submittedFormsRepresentative || false,
        user_id: profile.id, // Add user_id from profiles
        userFullName: profile.full_name, // Add userFullName from profiles
    };

    const { error } = await supabase
        .from('business_kyb')
        .insert([newFormData]);

    if (error) {
        setErrorMessage(`Error saving form data: ${error.message}`);
        setIsLoading(false);
    } else {
        alert('Form has been submitted successfully! Thanks!')
        navigate('/dashboard');
    }
};

  return (
    <>
      <Helmet>
        <title>Business KYB Form | IxoDex</title>
        <meta name="keywords" content="IxoDex, business, KYB, form" />
        <meta name="description" content="Business KYB Form - onboarding to IxoDex" />
        <meta name="robots" content="noindex, nofollow" />
        <link rel="canonical" href="/forms/business" />
      </Helmet>

      <div className="w-full max-w-4xl px-4 md:p-8">
        <h1>Business KYB Form</h1>

        <div className="text-sm">
          To help avoid delays or a refusal, please make sure you're:
          <ul>
            <li>carefully reading the details and requirements of each field;</li>
            <li>following the instructions; and</li>
            <li>filling and submitting any necessary subforms before continuing.</li>
          </ul>
        </div>

        <form className="flex flex-col" onSubmit={handleSubmit}>
            <CompanyDetails formData={formData} handleChange={handleChange} />
            {errorMessage && <div className="text-red-500">{errorMessage}</div>}
            <RegulationDetails formData={formData} handleChange={handleChange} />
            {errorMessage && <div className="text-red-500">{errorMessage}</div>}
            <OwnershipDetails formData={formData} handleChange={handleChange} />
            <DirectorDetails formData={formData} handleChange={handleChange} />
            <RepresentativeDetails formData={formData} handleChange={handleChange} />
            <DeclarationSection formData={formData} handleChange={handleChange} />
            {errorMessage && <div className="text-red-500 my-4">{errorMessage}</div>}
            <button className="my-8 py-2 px-4 rounded-md no-underline btn-active w-full md:w-[250px]" type="submit" disabled={isLoading}>
                {isLoading ? (
                    <div className="flex items-center justify-center">
                    <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291l-1.707-1.707C6.279 14.279 8 12 12 12v-4c-4.418 0-8 3.582-8 8h4c0-1.304.527-2.566 1.457-3.457z"></path>
                    </svg>
                    Submitting...
                    </div>
                ) : (
                    'Submit'
                )}
            </button>
        </form>
          </div>
    </>
  );
}