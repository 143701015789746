import React from 'react';
import { countries } from '../../../countries';

export default function PersonalInfoDetails({ formData, handleChange }) {
  return (
    <>
        <h2 className="mt-4">Personal Information</h2>
        <hr className="my-4" />

        {/* Full Name */}
        <div className="form-label required mt-4">Full Name</div>
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 sm:gap-2">
            <label className="mb-4">
                <input
                    className="h-12 w-full"
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                />
                <div className="form-comment text-justify">First Name</div>
            </label>
            <label className="mb-4">
                <input
                    className="h-12 w-full"
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                />
                <div className="form-comment text-justify">Last Name</div>
            </label>
        </div>

        {/* Date of Birth */}
        <label className="mb-4">
        <div className="form-label required">Date of Birth</div>
        <input
            className="h-12 w-full sm:w-1/2"
            type="date"
            name="dateOfBirth"
            value={formData.dateOfBirth}
            onChange={handleChange}
            required
            />
        </label>
        <label className="mb-4">
        <div className="form-label required">Country of Birth</div>
        <select
            className="text-sm h-12 w-full sm:w-1/2 rounded-md border border-btn-background px-2"
            name="countryOfBirth"
            value={formData.countryOfBirth}
            onChange={handleChange}
            required
        >
            <option value="" disabled>Please Select</option>
            {countries.map((countryOfBirth) => (
            <option key={countryOfBirth} value={countryOfBirth}>
                {countryOfBirth}
            </option>
            ))}
        </select>
        </label>

        {/* Tax ID */}
        <label className="mb-4">
        <div className="form-label required">Tax ID Number</div>
        <input
            className="h-12 w-full sm:w-1/2"
            type="text"
            name="taxId"
            value={formData.taxId}
            onChange={handleChange}
            required
        />
        </label>

        {/* Individual Email */}
        <label className="mb-4">
        <div className="form-label required">Email</div>
        <input
            className="h-12 w-full sm:w-1/2"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
        />
        </label>

        {/* Phone */}
        <label className="mb-4">
        <div className="form-label required">Phone Number</div>
        <input
            className="h-12 w-full sm:w-1/2"
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
        />
        <div className="form-comment">Full format: +370 1234 5678</div>
        </label>

        {/* Address */}
        <label className="mb-2">
        <div className="form-label required">Residential Address</div>
        <input
            className="h-12 w-full sm:w-1/2"
            type="text"
            name="addressStreet"
            value={formData.addressStreet}
            onChange={handleChange}
            required
        />
        <div className="form-comment">Street Address</div>
        </label>
        <label className="mb-2">
        <input
            className="h-12 w-full sm:w-1/2"
            type="text"
            name="addressCity"
            value={formData.addressCity}
            onChange={handleChange}
            required
        />
        <div className="form-comment">City</div>
        </label>
        <div className="w-full sm:w-1/2 grid grid-cols-2 gap-2">
        <label className="mb-2">
            <input
                className="h-12 w-full"
                type="text"
                name="addressPostalCode"
                value={formData.addressPostalCode}
                onChange={handleChange}
                required
                />
            <div className="form-comment">Postal Code</div>
            </label>
            <label className="mb-4">
            <select
                className="text-sm h-12 w-full rounded-md border border-btn-background px-2"
                name="addressCountry"
                value={formData.addressCountry}
                onChange={handleChange}
                required
            >
                <option value="" disabled>Please Select</option>
                {countries.map((addressCountry) => (
                <option key={addressCountry} value={addressCountry}>
                    {addressCountry}
                </option>
                ))}
            </select>
            <div className="form-comment">Country</div>
        </label>
        </div>

        {/* Do you have multiple nationalities? */}
        <label className="mb-4">
        <div className="form-label required">Do you have multiple nationalities?</div>
        <div className="my-4">
            <input
            className="mx-2"
            type="radio"
            id="isMultipleNationalitiesYes"
            name="isMultipleNationalities"
            value="true"
            checked={formData.isMultipleNationalities === true}
            onChange={handleChange}
            required
            />
            <label htmlFor="isMultipleNationalitiesYes">Yes</label>
            <br />
            <input
            className="mx-2"
            type="radio"
            id="isMultipleNationalitiesNo"
            name="isMultipleNationalities"
            value="false"
            checked={formData.isMultipleNationalities === false}
            onChange={handleChange}
            required
            />
            <label htmlFor="isMultipleNationalitiesNo">No</label>
        </div>
        </label>

        {/* Is US Person? */}
        <label className="mb-4">
        <div className="form-label required">Are you a U.S. Citizen, Resident or are otherwise deemed to be a "U.S. Person"?</div>
        <div className="my-4">
            <input
            className="mx-2"
            type="radio"
            id="isUsPersonYes"
            name="isUsPerson"
            value="true"
            checked={formData.isUsPerson === true}
            onChange={handleChange}
            required
            />
            <label htmlFor="isUsPersonYes">Yes</label>
            <br />
            <input
            className="mx-2"
            type="radio"
            id="isUsPersonNo"
            name="isUsPerson"
            value="false"
            checked={formData.isUsPerson === false}
            onChange={handleChange}
            required
            />
            <label htmlFor="isUsPersonNo">No</label>
        </div>
        </label>
    </>
  );
}