// src/pages/account/Account.jsx

import { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import Gravatar from './Gravatar';

export default function Account({ session }) {
  const [loading, setLoading] = useState(true);
  const [full_name, setFullName] = useState(null);
  const [website, setWebsite] = useState(null);
  const [avatar_url, setAvatarUrl] = useState(null);
  const [updateSuccess, setUpdateSuccess] = useState(false); // New state for confirmation message

  useEffect(() => {
    let ignore = false;
    async function getProfile() {
      setLoading(true);
      const { user } = session;

      const { data, error } = await supabase
        .from('profiles')
        .select(`full_name, website, avatar_url`)
        .eq('id', user.id)
        .single();

      if (!ignore) {
        if (error) {
          console.warn(error);
        } else if (data) {
          setFullName(data.full_name);
          setWebsite(data.website);
          setAvatarUrl(data.avatar_url);
        }
      }

      setLoading(false);
    }

    getProfile();

    return () => {
      ignore = true;
    };
  }, [session]);

  async function updateProfile(event, avatarUrl) {
    event.preventDefault();

    setLoading(true);
    const { user } = session;

    const updates = {
      id: user.id,
      full_name,
      website,
      avatar_url: avatarUrl,
      updated_at: new Date(),
    };

    const { error } = await supabase.from('profiles').upsert(updates);

    if (error) {
      alert(error.message);
    } else {
      setAvatarUrl(avatarUrl);
      setUpdateSuccess(true); // Show confirmation message
      setTimeout(() => setUpdateSuccess(false), 3000); // Hide after 3 seconds
    }
    setLoading(false);
  }

  return (
    <div className="w-full max-w-4xl px-4 md:p-8">
      <h1>Account</h1>
      <div className="flex justify-start">
        <Gravatar
          email={session.user.email}
          avatarUrl={avatar_url}
          className="rounded-full h-24 w-24"
        />
      </div>
      <form onSubmit={updateProfile} className="form-widget mt-4">
        <div>
          <div className="form-label">Email</div>
          <input className="h-12 w-full sm:w-1/2" id="email" type="text" value={session.user.email} disabled />
        </div>
        <div>
          <div className="form-label mt-4">Full Name</div>
          <input
            id="full_name"
            className="h-12 w-full sm:w-1/2"
            type="text"
            required
            value={full_name || ''}
            onChange={(e) => setFullName(e.target.value)}
          />
        </div>
        <div>
          <div className="form-label mt-4">Website</div>
          <input
            id="website"
            className="h-12 w-full sm:w-1/2"
            type="url"
            value={website || ''}
            onChange={(e) => setWebsite(e.target.value)}
          />
        </div>

        <div className="w-full md:w-1/2 grid grid-cols-2 gap-1">
          <div>
            <button className="mt-8 mb-2 h-12 py-2 px-4 rounded-md no-underline btn-active w-full" type="submit" disabled={loading}>
              {loading ? 'Updating ...' : 'Update'}
            </button>
          </div>
          <div>
            <button className="mt-8 mb-2 h-12 py-2 px-4 rounded-md no-underline border bg-gray-50 hover:bg-gray-100 w-full" type="button" onClick={() => supabase.auth.signOut()}>
              Sign Out
            </button>
          </div>
        </div>

        {updateSuccess && (
          <div className="text-green-500 mt-4">
            Profile updated successfully!
          </div>
        )}
      </form>
    </div>
  );
}