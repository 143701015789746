import React from 'react';

export default function SourceOfWealthDetails({ formData, handleChange }) {
  return (
    <>
        <h2>Source of Wealth Information</h2>
        <hr className="my-4" />

        {/* Net worth */}
        <label className="my-4">
        <div className="form-label required">Indicate your estimated total net worth</div>
        <select
            className="text-sm h-12 w-full sm:w-1/2 rounded-md border border-btn-background px-2"
            name="estimatedTotalNetWorth"
            value={formData.estimatedTotalNetWorth}
            onChange={handleChange}
            required
        >
            <option value="" disabled>Please Select</option>
            <option value="Under €100,000">Under €100,000</option>
            <option value="€100,000 - €500,000">€100,000 - €500,000</option>
            <option value="€500,000 - €1M">€500,000 - €1M</option>
            <option value="€1M - €5M">€1M - €5M</option>
            <option value="Over €5M">Over €5M</option>
        </select>
        </label>

        {/* Source of Wealth */}
        <label className="mb-4">
            <div className="form-label required">What are the source(s) from where you obtained your total net worth:</div>
            <div className="ml-2">
                <label>
                    <input
                        type="checkbox"
                        name="sourceOfWealth"
                        value="Salary"
                        checked={formData.sourceOfWealth.includes('Salary')}
                        onChange={handleChange}
                    />
                    <span className="mx-4">Salary</span>
                </label>
                <br />
                <label>
                    <input
                        type="checkbox"
                        name="sourceOfWealth"
                        value="Profession or trade"
                        checked={formData.sourceOfWealth.includes('Profession or trade')}
                        onChange={handleChange}
                    />
                    <span className="mx-4">Profession or Trade</span>
                </label>
                <br />
                <label>
                    <input
                        type="checkbox"
                        name="sourceOfWealth"
                        value="Pension/retirement income"
                        checked={formData.sourceOfWealth.includes('Pension/retirement income')}
                        onChange={handleChange}
                    />
                    <span className="mx-4">Pension or Retirement income</span>
                </label>
                <br />
                <label>
                    <input
                        type="checkbox"
                        name="sourceOfWealth"
                        value="Asset management"
                        checked={formData.sourceOfWealth.includes('Asset management')}
                        onChange={handleChange}
                    />
                    <span className="mx-4">Asset management (bonds, equities, others)</span>
                </label>
                <br />
                <label>
                    <input
                        type="checkbox"
                        name="sourceOfWealth"
                        value="Business interests"
                        checked={formData.sourceOfWealth.includes('Business interests')}
                        onChange={handleChange}
                    />
                    <span className="mx-4">Business interests (incl. company profits and dividends)</span>
                </label>
                <br />
                <label>
                    <input
                        type="checkbox"
                        name="sourceOfWealth"
                        value="Property"
                        checked={formData.sourceOfWealth.includes('Property')}
                        onChange={handleChange}
                    />
                    <span className="mx-4">Property (including rental and sale)</span>
                </label>
                <br />
                <label>
                    <input
                        type="checkbox"
                        name="sourceOfWealth"
                        value="Inheritance"
                        checked={formData.sourceOfWealth.includes('Inheritance')}
                        onChange={handleChange}
                    />
                    <span className="mx-4">Inheritance</span>
                </label>
                <br />
                <label>
                    <input
                        type="checkbox"
                        name="sourceOfWealth"
                        value="Gift or Donation"
                        checked={formData.sourceOfWealth.includes('Gift or Donation')}
                        onChange={handleChange}
                    />
                    <span className="mx-4">Gift / Donation</span>
                </label>
                <br />
                <label>
                    <input
                        type="checkbox"
                        name="sourceOfWealth"
                        value="Private Settlement"
                        checked={formData.sourceOfWealth.includes('Private Settlement')}
                        onChange={handleChange}
                    />
                    <span className="mx-4">Private Settlement (accidents, insurance, divorce, etc.)</span>
                </label>
                <br />
                <label>
                    <input
                        type="checkbox"
                        name="sourceOfWealth"
                        value="Lottery Gambling"
                        checked={formData.sourceOfWealth.includes('Lottery Gambling')}
                        onChange={handleChange}
                    />
                    <span className="mx-4">Lottery / Gambling</span>
                </label>
                <br />
                <label>
                    <input
                        type="checkbox"
                        name="sourceOfWealth"
                        value="Other"
                        checked={formData.sourceOfWealth.includes('Other')}
                        onChange={handleChange}
                    />
                    <span className="mx-4">Other</span>
                </label>
            </div>
        </label>

        {/* Source of Wealth Evidence */}
        <label className="mb-4">
            <div className="form-label required">Please provide a documentary evidence of your source of wealth.</div>
            <input
                className="text-sm py-4 w-full sm:w-1/2 overflow-hidden"
                type="file"
                name="sourceOfWealthEvidence"
                onChange={handleChange}
                required
            />
            <div className="form-comment text-justify">This is the document attesting to the origin of your accumulated wealth. Depending on the source, this may be, but not limited to, a personal bank statement, a tax form, a dividends declaration or a payslip.</div>
        </label>

        {/* Source of Wealth Description */}
        <label className="mb-2">
        <div className="form-label required">Description of the source(s) from which you obtained your net worth.</div>
        <textarea
            className="h-24 w-full sm:w-1/2"
            name="sourceOfWealthDescription"
            value={formData.sourceOfWealthDescription}
            onChange={handleChange}
            required
            placeholder="Type here..."
            rows={4}
            autoFocus
        />
        </label>
    </>
  );
}