import React from 'react';

export default function RepresentativeDetails({ formData, handleChange }) {
  return (
    <>
      <h2 className="mt-8">Representative</h2>
      <hr className="my-4" />

      <p className="text-sm text-justify mt-4 mb-6 p-4 bg-btn-background border border-btn-background-hover rounded-md">
        The Main Representative is <strong>the person who represents the Company in this application</strong>. A Representative refers to the natural person who has Managerial or Executive powers over the company, in either an Authorized Representative or Executive role, possessing the necessary powers to make decisions on behalf of the company.
      </p>

      <label className="mb-4">
        <div className="form-label required">Are you the Main Representative of the Company?</div>
        <div className="my-4">
          <input
            className="mx-2"
            type="radio"
            id="isMainRepYes"
            name="isMainRep"
            value="true"
            checked={formData.isMainRep === true}
            onChange={handleChange}
            required
          />
          <label htmlFor="isMainRepYes">Yes</label>
          <br />
          <input
            className="mx-2"
            type="radio"
            id="isMainRepNo"
            name="isMainRep"
            value="false"
            checked={formData.isMainRep === false}
            onChange={handleChange}
            required
          />
          <label htmlFor="isMainRepNo">No</label>
        </div>
      </label>
    </>
  );
}