import React from 'react';
import { countries } from '../../../countries';

export default function CompanyDetails({ formData, handleChange }) {
    return (
        <>
            {/* companyEmail */}
            <label className="mt-2 mb-4">
            <div className="form-label required">Company Email</div>
            <input
                className="h-12 w-full sm:w-1/2"
                type="email"
                name="companyEmail"
                value={formData.companyEmail}
                onChange={handleChange}
                required
            />
            </label>

            {/* Legal Name */}
            <label className="mb-4">
            <div className="form-label required">Company Legal Name</div>
            <input
                className="h-12 w-full sm:w-1/2"
                type="text"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                required
            />
            <div className="form-comment">Must match your certificate of incorporation document</div>
            </label>

            {/* Legal Address */}
            <label className="mb-2">
            <div className="form-label required">Company Legal Address</div>
            <input
                className="h-12 w-full sm:w-1/2"
                type="text"
                name="streetAddress"
                value={formData.streetAddress}
                onChange={handleChange}
                required
            />
            <div className="form-comment">Street Address</div>
            </label>

            <label className="mb-2">
            <input
                className="h-12 w-full sm:w-1/2"
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                required
            />
            <div className="form-comment">City</div>
            </label>

            <div className="w-full sm:w-1/2 grid grid-cols-2 gap-2 mb-4">
                <label className="mb-2">
                <input
                    className="h-12 w-full"
                    type="text"
                    name="postalCode"
                    value={formData.postalCode}
                    onChange={handleChange}
                    required
                />
                <div className="form-comment">Postal/Zip Code</div>
                </label>

                <label className="mb-2">
                <select
                    className="text-sm h-12 w-full rounded-md border border-btn-background px-2"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    required
                >
                    <option value="" disabled>Please Select</option>
                    {countries.map((country) => (
                    <option key={country} value={country}>
                        {country}
                    </option>
                    ))}
                </select>
                <div className="form-comment">Country</div>
                </label>
            </div>

            {/* hasTradeName */}
            <label className="mb-4">
            <div className="form-label required">Does the company have any trade name, trademark or any other business name which the company is known for?</div>
            <div className="radio-buttons">
                <input
                className="mx-2"
                type="radio"
                id="yes"
                name="hasTradeName"
                value="yes"
                checked={formData.hasTradeName === 'yes'}
                onChange={handleChange}
                required
                />
                <label htmlFor="yes">Yes</label>
                <br />
                <input
                className="mx-2"
                type="radio"
                id="no"
                name="hasTradeName"
                value="no"
                checked={formData.hasTradeName === 'no'}
                onChange={handleChange}
                required
                />
                <label htmlFor="no">No</label>
            </div>
            </label>

            {/* Trade Name */}
            {formData.hasTradeName === 'yes' && (
            <label className="mb-4">
                <div className="form-label required">Enter the trade name</div>
                <input
                className="h-12 w-full sm:w-1/2"
                name="tradeName"
                value={formData.tradeName}
                onChange={handleChange}
                required
                />
            </label>
            )}

            {/* Company Reg Number */}
            <label className="mb-4">
            <div className="form-label required">Company Registration Number</div>
            <input
                className="h-12 w-full sm:w-[50%]"
                type="text"
                name="registrationNumber"
                value={formData.registrationNumber}
                onChange={handleChange}
                required
            />
            </label>

            {/* LEI code */}
            <label className="mb-4">
            <div className="form-label">LEI code</div>
            <input
                className="h-12 w-full sm:w-[50%]"
                type="text"
                name="lei"
                value={formData.lei}
                onChange={handleChange}
            />
            </label>

            {/* Detailed description of business activities */}
            <label className="mb-4">
                <div className="form-label required">Detailed description of business activities</div>
                <textarea
                    className="h-24 w-full sm:w-1/2"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    required
                />
            </label>

            {/* Bank Statement */}
            <label className="mb-4">
                <div className="form-label required">Please upload a company's bank statement dated within the last 90 days</div>
                <input
                    className="text-sm my-4 py-2 w-full sm:w-1/2 overflow-hidden"
                    type="file"
                    name="bankStatement"
                    onChange={handleChange}
                    required
                />
                <div className="form-comment text-justify">Upload a document attesting the account of the destination of your payout. In the case of fiat currency payouts, this should be a bank statement of that account dated within the last 90 days. In the case of cryptocurrency payouts, this should be a document attesting to your wallet or account address (e.g. document extracted from the exchange with reference to your personal details; or an extracted image of your address).</div>
            </label>
        </>
    );
}