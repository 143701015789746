import React from 'react';
import { countries } from '../../../countries';

export default function RegulationDetails({ formData, handleChange }) {
    return (
        <>
<h2>Applicable Regulations</h2>
            <hr className="my-4" />

            <p className="text-sm text-justify mt-4 mb-4 p-4 bg-btn-background border border-btn-background-hover rounded-md">Regulated activities include providing any type of <strong>financial services, gambling or operating in a restricted sector</strong> deemed to require regulatory approval in their respective operating jurisdictions.</p>
            
            {/* isRegulated */}
            <label className="mb-4">
                <div className="form-label required">Is your company regulated?</div>
                <div className="mt-4">
                    <input
                    className="mx-2"
                    type="radio"
                    id="isRegulated_yes"
                    name="isRegulated"
                    value="yes"
                    checked={formData.isRegulated === 'yes' }
                    onChange={handleChange}
                    required
                    />
                    <label htmlFor="isRegulated_yes">Yes</label>
                    <br />
                    <input
                    className="mx-2"
                    type="radio"
                    id="isRegulated_no"
                    name="isRegulated"
                    value="no"
                    checked={formData.isRegulated === 'no' }
                    onChange={handleChange}
                    required
                    />
                    <label htmlFor="isRegulated_no">No</label>
                </div>
            </label>

            {/* Regulated company */}
            {formData.isRegulated === 'yes' && (

                <>
                    <label className="my-4">
                    <div className="form-label required">Where is your company regulated?</div>
                    <select
                        className="text-sm mt-2 h-12 w-full sm:w-1/2 rounded-md border border-btn-background px-2"
                        name="regulationCountry"
                        value={formData.regulationCountry}
                        onChange={handleChange}
                        required
                    >
                        <option value="" disabled>Please Select</option>
                        {countries.map((regulationCountry) => (
                        <option key={regulationCountry} value={regulationCountry}>
                            {regulationCountry}
                        </option>
                        ))}
                    </select>
                    <div className="form-comment">Country</div>
                    </label>
                    
                    <label className="mb-4">
                        <div className="form-label required">Name of your Main Regulator or Supervisory Authority</div>
                        <input
                        className="h-12 w-full sm:w-1/2"
                        name="regulatorName"
                        value={formData.regulatorName}
                        onChange={handleChange}
                        required
                        />
                    </label>

                    <label className="mb-4">
                        <div className="form-label required">License Number</div>
                        <input
                        className="h-12 w-full sm:w-1/2"
                        name="licenseNumber"
                        value={formData.licenseNumber}
                        onChange={handleChange}
                        required
                        />
                    </label>

                    <label className="mb-4">
                        <div className="form-label required">Link or Contact to verify your License</div>
                        <input
                        className="h-12 w-full sm:w-1/2"
                        name="licenseLink"
                        value={formData.licenseLink}
                        onChange={handleChange}
                        required
                        />
                    </label>

                    {/* Does your company provide Financial Services? */}
                    <label className="mb-4">
                    <div className="form-label required">Does your company provide Financial Services?</div>
                    <div className="mt-4">
                        <input
                        className="mx-2"
                        type="radio"
                        id="providesFinancialServices_yes"
                        name="providesFinancialServices"
                        value="yes"
                        checked={formData.providesFinancialServices === 'yes' }
                        onChange={handleChange}
                        required
                        />
                        <label htmlFor="providesFinancialServices_yes">Yes</label>
                        <br />
                        <input
                        className="mx-2"
                        type="radio"
                        id="providesFinancialServices_no"
                        name="providesFinancialServices"
                        value="no"
                        checked={formData.providesFinancialServices === 'no' }
                        onChange={handleChange}
                        required
                        />
                        <label htmlFor="providesFinancialServices_no">No</label>
                    </div>
                    </label>
                    <div className="form-comment text-justify">Regulated financial activities include providing <strong>any type of money transmission</strong>, forex or cryptocurrency exchange or custody, retail, private or commercial banking, among others.</div>
                </>
            )}
        </>
    );
}