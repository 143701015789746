import { LogOut, ChevronLast, ChevronFirst } from "lucide-react"
import { useNavigate, useLocation } from 'react-router-dom'
import { useContext, createContext, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Gravatar from "../pages/account/Gravatar"
import { supabase } from "../supabaseClient"

const SidebarContext = createContext()

export default function Sidebar({ children, expanded, setExpanded }) {
  const [userData, setUserData] = useState({
    full_name: '',
    email: '',
    avatar_url: ''
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getUserData() {
      setLoading(true);

      const { data: { session }, error } = await supabase.auth.getSession();

      if (error) {
        console.error('Error fetching session:', error);
        setLoading(false);
        return;
      }

      if (session) {
        const user = session.user;

        const { data, error: profileError } = await supabase
          .from('profiles')
          .select(`full_name, avatar_url`)
          .eq('id', user.id)
          .single();

        if (profileError) {
          console.error('Error loading user data:', profileError);
        } else if (data) {
          setUserData({
            full_name: data.full_name,
            email: user.email,
            avatar_url: data.avatar_url,
          });
        }
      } else {
        console.error('No session found.');
      }

      setLoading(false);
    }

    getUserData();
  }, []);
  return (
    <aside className={`fixed top-0 left-0 h-screen ${expanded ? "w-64" : "w-16"} transition-all`}>
      <nav className="h-full flex flex-col bg-white border-r shadow-sm">
        <div className="p-4 pb-2 flex justify-between items-center">
          <img
            src="/logo_big.png"
            className={`overflow-hidden transition-all ${expanded ? "w-32" : "w-0"}`}
            alt=""
          />
          <button
            onClick={() => setExpanded((curr) => !curr)}
            className="p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100"
          >
            {expanded ? <ChevronFirst /> : <ChevronLast />}
          </button>
        </div>

        <SidebarContext.Provider value={{ expanded }}>
          <ul className="flex-1 px-3 list-none">{children}</ul>
        </SidebarContext.Provider>

        <div className="border-t flex p-3">
          <Link to="/account" title="Account">
            <Gravatar
              email={userData.email}
              avatarUrl={userData.avatar_url}
              className="w-10 rounded-full"
            />
            {/* <img
              src="https://ui-avatars.com/api/?background=c7d2fe&color=3730a3&bold=true"
              alt=""
              className="w-10 h-10 rounded-md"
            /> */}
          </Link>
          <div
            className={`
              flex justify-between items-center
              overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}
          `}
          >
            <div className="leading-4">
            {loading? (<h4 className="font-semibold">Updating...</h4>) :
            <h4 className="font-semibold">{userData.full_name}</h4>}
            <span className="text-xs text-gray-600">{userData.email}</span>
            </div>
            {expanded ? <button type="button" onClick={() => supabase.auth.signOut()}><LogOut size={20} /></button> : <LogOut size={20} />} 
          </div>
        </div>
      </nav>
    </aside>
  )
}

export function SidebarItem({ icon, text, alert, to }) {
  const { expanded } = useContext(SidebarContext);
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = location.pathname === to;

  const handleClick = () => {
    navigate(to);
  };

  return (
    <li
      className={`
        relative flex items-center py-2 px-3 my-1
        rounded-md cursor-pointer text-sm
        transition-colors group
        ${isActive ? "bg-gradient-to-tr btn-active" : "hover:bg-green-50 text-gray-600"}
      `}
      onClick={handleClick}
    >
      {icon}
      <span
        className={`overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}`}
      >
        {text}
      </span>
      {alert && (
        <div
          className={`absolute right-2 w-2 h-2 rounded bg-green-400 ${expanded ? "" : "top-2"}`}
        />
      )}

      {!expanded && (
        <div
          className={`
            absolute left-full rounded-md px-2 py-1 ml-6
            bg-green-100 text-black text-sm
            invisible opacity-20 -translate-x-3 transition-all
            group-hover:visible group-hover:opacity-100 group-hover:translate-x-0
          `}
        >
          {text}
        </div>
      )}
    </li>
  );
}