// src/pages/AboutPage.tsx
import React from 'react';

const Dashboard = () => {
    return (
        <div>
            <h1>Dashboard</h1>
            <p>This page provides information about our company.</p>
        </div>
    );
};

export default Dashboard;