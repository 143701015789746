// src/pages/account/Gravatar.jsx

import md5 from 'md5'

export default function Gravatar({ email, avatarUrl, className }) {
  function getGravatarUrl(email) {
    const hash = md5(email.trim().toLowerCase())
    return `https://www.gravatar.com/avatar/${hash}?d=identicon`
  }

  return (
    <img
      src={avatarUrl || getGravatarUrl(email)}
      alt="Avatar"
      className={className}
    />
  )
}

