import React from 'react';

export default function AssociatedCompanyDetails({ formData, handleChange }) {
  return (
    <>
      <h2>Associated Company</h2>
      <hr className="my-4" />
      
      <label className="mb-4">
        <div className="form-label required">Company Name</div>
        <input
          className="h-12 w-full sm:w-1/2"
          type="text"
          name="companyName"
          value={formData.companyName}
          onChange={handleChange}
          required
        />
      </label>

      <label>
        <div className="form-label required">Company Email</div>
        <input
          className="h-12 w-full sm:w-1/2"
          type="email"
          name="companyEmail"
          value={formData.companyEmail}
          onChange={handleChange}
          required
        />
      </label>
      <div className="form-comment">Must match the email associated with your <a href="/forms/business" title="Business KYB Form" target="_blank">Business KYB Form</a></div>
    </>
  );
}