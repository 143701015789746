//src/pages/IndividualForm.jsx

import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'; // Replace useRouter with useNavigate from react-router-dom
import { v4 as uuidv4 } from 'uuid';
import { supabase } from "../../../supabaseClient";
import { Helmet } from "react-helmet-async"; // For handling meta tags
import AssociatedCompanyDetails from "./AssociatedCompanyDetails";
import RepresentativeDetails from "./RepresentativeDetails";
import RelationToCompanyDetails from "./RelationToCompanyDetails";
import PersonalInfoDetails from "./PersonalInfoDetails";
import IdentityDocumentDetails from "./IdentityDocumentDetails";
import AddressDocumentDetails from "./AddressDocumentDetails";
import SourceOfWealthDetails from "./SourceOfWealthDetails";
import PepDetails from "./PepDetails";
import DeclarationSection from "./DeclarationSection";
import { Link } from "react-router-dom";

export default function IndividualForm() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    companyName: '',
    companyEmail: '',
    isMainRep: null,
    relationToCompany: '',
    title: '',
    OwnershipPercentage: 0.00,
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    countryOfBirth: '',
    taxId: '',
    email: '',
    phone: '',
    addressStreet: '',
    addressCity: '',
    addressPostalCode: '',
    addressCountry: '',
    isMultipleNationalities: null,
    isUsPerson: null,
    idDocumentType: '',
    idDocumentFront: null,
    idDocumentBack: null,
    addressDocumentType: '',
    addressDocument: null,
    estimatedTotalNetWorth: '',
    sourceOfWealth: [],
    sourceOfWealthEvidence: null,
    sourceOfWealthDescription: '',
    isPep: null,
    pepCountry: '',
    pepTitle: '',
    pepDetails: '',
    acceptedTerms: false,
  });

  const [errorMessage, setErrorMessage] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, files, checked } = e.target;
  
    if (type === 'file' && files && files.length > 0) {
      const file = files[0];
      const maxFileSize = 1 * 1024 * 1024; // 1MB in bytes
  
      if (file.size > maxFileSize) {
        setErrorMessage(`File size should not exceed 1MB. Your file is ${(file.size / 1024 / 1024).toFixed(2)}MB.`);
        return;
      } else {
        setErrorMessage(null); // Clear previous error message
      }
  
      setFormData((prev) => ({
        ...prev,
        [name]: file, // Store the file object
      }));
    } else if (name === 'sourceOfWealth') {
      setFormData((prev) => {
        const updatedSources = prev.sourceOfWealth.includes(value)
          ? prev.sourceOfWealth.filter((source) => source !== value)
          : [...prev.sourceOfWealth, value];
        return {
          ...prev,
          sourceOfWealth: updatedSources,
        };
      });
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : (name === 'isMainRep' || name === 'isMultipleNationalities' || name === 'isUsPerson' || name === 'isPep') ? value === 'true' : value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    try {
      // Get the current user's profile information
      const { data: { user }, error: userError } = await supabase.auth.getUser();
  
      if (userError || !user) {
        throw new Error('Failed to get user');
      }
  
      const userId = user.id; // This is the UUID of the authenticated user
  
      // Fetch the user's profile data from the profiles table
      const { data: profile, error: profileError } = await supabase
        .from('profiles')
        .select('id, full_name')
        .eq('id', userId)
        .single();
  
      if (profileError || !profile) {
        throw new Error('Failed to fetch user profile');
      }
  
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split('T')[0];
      const formattedTime = currentDate.toTimeString().split(' ')[0].replace(/:/g, '-');
  
      const sanitizeString = (str) => {
        return str
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/[^a-zA-Z0-9-_]/g, '-')
          .replace(/\s+/g, '-');
      };
      
      const sanitizePath = (path) => {
        const parts = path.split('/');
        const filename = parts.pop() || '';
        const sanitizedParts = parts.map(part => sanitizeString(part));
        const sanitizedFilename = filename.split('.').slice(0, -1).join('.')
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/[^a-zA-Z0-9-_]/g, '-')
          .replace(/\s+/g, '-');
        const extension = filename.split('.').pop();
        const uniqueIdentifier = uuidv4();
        return [...sanitizedParts, `${sanitizedFilename}-${uniqueIdentifier}.${extension}`].join('/');
      };
      
      let idDocumentFrontUrl = '';
      if (formData.idDocumentFront) {
        const filePath = sanitizePath(`${formattedDate}/${sanitizeString(formData.companyName)}/${sanitizeString(formData.firstName)}-${sanitizeString(formData.lastName)}/${formattedTime}/id-document/${formData.idDocumentFront.name}`);
        const { data: uploadData, error: uploadError } = await supabase.storage
          .from('individual-uploads')
          .upload(filePath, formData.idDocumentFront);
      
        if (uploadError) {
          throw new Error(`Error uploading file: ${uploadError.message}`);
        }
        idDocumentFrontUrl = uploadData?.path ?? '';
      }
      
      let idDocumentBackUrl = '';
      if (formData.idDocumentBack) {
        const filePath = sanitizePath(`${formattedDate}/${sanitizeString(formData.companyName)}/${sanitizeString(formData.firstName)}-${sanitizeString(formData.lastName)}/${formattedTime}/id-document/${formData.idDocumentBack.name}`);
        const { data: uploadData, error: uploadError } = await supabase.storage
          .from('individual-uploads')
          .upload(filePath, formData.idDocumentBack);
      
        if (uploadError) {
          throw new Error(`Error uploading file: ${uploadError.message}`);
        }
        idDocumentBackUrl = uploadData?.path ?? '';
      }
      
      let addressDocumentUrl = '';
      if (formData.addressDocument) {
        const filePath = sanitizePath(`${formattedDate}/${sanitizeString(formData.companyName)}/${sanitizeString(formData.firstName)}-${sanitizeString(formData.lastName)}/${formattedTime}/address-document/${formData.addressDocument.name}`);
        const { data: uploadData, error: uploadError } = await supabase.storage
          .from('individual-uploads')
          .upload(filePath, formData.addressDocument);
      
        if (uploadError) {
          throw new Error(`Error uploading file: ${uploadError.message}`);
        }
        addressDocumentUrl = uploadData?.path ?? '';
      }
      
      let sourceOfWealthEvidenceUrl = '';
      if (formData.sourceOfWealthEvidence) {
        const filePath = sanitizePath(`${formattedDate}/${sanitizeString(formData.companyName)}/${sanitizeString(formData.firstName)}-${sanitizeString(formData.lastName)}/${formattedTime}/sow-document/${formData.sourceOfWealthEvidence.name}`);
        const { data: uploadData, error: uploadError } = await supabase.storage
          .from('individual-uploads')
          .upload(filePath, formData.sourceOfWealthEvidence);
      
        if (uploadError) {
          throw new Error(`Error uploading file: ${uploadError.message}`);
        }
        sourceOfWealthEvidenceUrl = uploadData?.path ?? '';
      }
      
      const newFormData = { 
        ...formData, 
        user_id: profile.id, // Add the user_id from the profile
        userFullName: profile.full_name, // Add the userFullName from the profile
        idDocumentFront: idDocumentFrontUrl,
        idDocumentBack: idDocumentBackUrl,
        addressDocument: addressDocumentUrl,
        sourceOfWealthEvidence: sourceOfWealthEvidenceUrl,
        submissionDate: currentDate.toISOString(),
        acceptedTerms: formData.acceptedTerms || false,
      };
    
      const { error } = await supabase
        .from('individual_kyb')
        .insert([newFormData]);
    
      if (error) {
        throw new Error(`Error saving form data: ${error.message}`);
      }
  
      alert('Form has been submitted successfully! Thanks!')
      navigate('/dashboard');
  
    } catch (error) {
      console.error('Error submitting form:', error.message);
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Individual KYB Form | IxoDex</title>
        <meta name="keywords" content="IxoDex, individual, KYB, form" />
        <meta name="description" content="Individual KYB Form - onboarding to IxoDex" />
        <meta name="robots" content="noindex, nofollow" />
        <link rel="canonical" href="/forms/individual" />
      </Helmet>
        <div className="w-full max-w-4xl px-4 md:p-8">
        <h1>Individual KYB Form</h1>

        <p className="text-sm text-justify mt-4 mb-6 p-4 bg-btn-background border border-btn-background-hover rounded-md bg-gray-200">
          Use this form only in case you represent a legal entity. For personal account, use the <Link to="/forms/personal" title="Personal KYC">Personal KYC</Link> form.
        </p>

        <div className="text-sm">
            To help avoid delays or a refusal, please make sure you're:
            <ul>
                <li>carefully reading the details and requirements of each field; and</li>
                <li>following the instructions.</li>
            </ul>
        </div>

        <form className="mt-8 flex flex-col" onSubmit={handleSubmit}>
            <AssociatedCompanyDetails formData={formData} handleChange={handleChange} />
            <RepresentativeDetails formData={formData} handleChange={handleChange} />
            <RelationToCompanyDetails formData={formData} handleChange={handleChange} />
            <PersonalInfoDetails formData={formData} handleChange={handleChange} />
            <IdentityDocumentDetails formData={formData} handleChange={handleChange} />
            {errorMessage && <div className="text-red-500">{errorMessage}</div>}
            <AddressDocumentDetails formData={formData} handleChange={handleChange} />
            {errorMessage && <div className="text-red-500">{errorMessage}</div>}
            <SourceOfWealthDetails formData={formData} handleChange={handleChange} />
            <PepDetails formData={formData} handleChange={handleChange} />
            <DeclarationSection formData={formData} handleChange={handleChange} />
            {errorMessage && <div className="text-red-500 my-4">{errorMessage}</div>}
            <button className="my-8 py-2 px-4 rounded-md no-underline btn-active w-full md:w-[250px]" type="submit" disabled={isLoading}>
            {isLoading ? (
                <div className="flex items-center justify-center">
                <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291l-1.707-1.707C6.279 14.279 8 12 12 12v-4c-4.418 0-8 3.582-8 8h4c0-1.304.527-2.566 1.457-3.457z"></path>
                </svg>
                Submitting...
                </div>
            ) : (
                'Submit'
            )}
            </button>
        </form>
        </div> 
    </>
  );
}     